import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import {BrowserRouter} from "react-router-dom";
import {AuthConstants} from "@fce-bees/fce-common-components";

const delayForMockToFinishLoading = 1000; //milliseconds

const disableAuth = import.meta.env.VITE_FINAPP_DISABLE_AUTH == "true";

if (disableAuth) {
  const expiry = new Date().setFullYear(new Date().getFullYear() + 1);
  document.cookie = `${AuthConstants.authCookieName}=cd069c20-ba53-4132-8905-5f3290f11c81; expires=${expiry}; path=/`;
}

function maybeStartMocks() {
  if (import.meta.env.VITE_FINAPP_MOCKS_ENABLED === "true") {
    return import("./mocks/browser")
      .then(({worker}) => worker.start())
      .then(() => new Promise((resolve) => setTimeout(resolve, delayForMockToFinishLoading)));
  }
  return Promise.resolve();
}

(async function bootstrap() {
  await maybeStartMocks();

  ReactDOM.createRoot(document.getElementById("app")!).render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
})();
